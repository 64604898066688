<template>
    <div class="block">
        <el-carousel height="150px">
            <el-carousel-item v-for="item in propValue" :key="item">
                <el-image class="banner-img" :src="item" fit="cover"/>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    props: {
        propValue: {
            type: Array,
            require: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.banner-img{
    height: 150;
    width: 100%;
}
</style>